import type { GeographyValueWithPolygon } from '@indomita-website/search/types';

import { createAtomicStateDerivedAtom } from 'src/atoms/atomic-state';

import { geographySearchAtom } from 'src/components/ReactGeographySearch/atoms';

import type { GeographySearchCircle, GeographySearchPolygon } from './types';

import {
  deserializePolygonPath,
  ENTITY_TYPE,
  geographyToSearchParams,
  polygonApi,
} from './utils';

const convertPolygonToMetroCircle = (polygon: GeographyValueWithPolygon) => ({
  type: 'circle',
  radius: 400,
  point: { lat: polygon.lat, lng: polygon.lng },
});

export const geographySearchParamsAtom = createAtomicStateDerivedAtom({
  key: 'GeographySearchParams',
  get: ({ get }) => {
    const geography = get(geographySearchAtom);

    if (geography !== null) {
      return geographyToSearchParams(geography);
    }

    return {};
  },
  deepEqualityCheck: true,
});

export const geographySearchPolygonsAtom = createAtomicStateDerivedAtom<
  Promise<(GeographySearchPolygon | GeographySearchCircle)[]>
>({
  key: 'GeographySearchPolygonsPromises',
  get: async ({ get }) => {
    const geography = get(geographySearchAtom);

    if (geography?.searchType === 'place') {
      const entities = Promise.all(
        geography.value.map((item) => {
          if (item.type === ENTITY_TYPE.metroLine) {
            return polygonApi(item).then((res) => {
              const metroPolygons = res.polygons[0].children;

              if (!metroPolygons) return [];

              return (
                metroPolygons as unknown as GeographyValueWithPolygon[]
              ).map(convertPolygonToMetroCircle);
            });
          }

          if (item.type === ENTITY_TYPE.metro) {
            return polygonApi(item).then((res) =>
              res.polygons.map(convertPolygonToMetroCircle)
            );
          }

          return polygonApi(item).then((res) => {
            const polygonsPath = res.polygons.flatMap((poly) => poly.path);

            return deserializePolygonPath(polygonsPath).map((coordinates) => ({
              coordinates,
              type: 'polygon',
            }));
          });
        })
      );

      return entities.then((e) => e.flat());
    }

    if (geography?.searchType === 'circle') {
      const [lat, lng] = geography.value.center;

      return Promise.resolve([
        {
          type: 'circle',
          point: { lat, lng },
          radius: geography.value.radius,
        },
      ]);
    } else if (geography?.searchType === 'polygon') {
      return Promise.resolve([
        {
          type: 'polygon',
          coordinates: geography.value.points.map(([lat, lng]) => ({
            lat,
            lng,
          })),
        },
      ]);
    }

    return Promise.resolve([]);
  },
});
